import * as React from "react"
import { Container,Box, Grid, Typography, makeStyles } from "@material-ui/core"
import Seo from "../../components/seo"
import Menu from "../../components/festival/menu"
import { StaticImage } from "gatsby-plugin-image"

const useStyles = makeStyles(theme => ({
  textBox: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(4),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    height: "100%",
    clipPath: "polygon(0 0, 100% 1%, 100% 84%, 0% 100%)"
  },
  boxText: {
    color: theme.palette.background.default,
    fontWeight: 800
  },
}));


const IndexPage = () => {
  const classes = useStyles();
  return (

    <Container maxWidth="lg">
    <Seo title="Tomatostories" />
      <Box>
      <Grid container spacing={2}>
          <Grid item xs={12}>
          <Menu/>
          </Grid>
      
          <Grid item md={8} xs={12}>
            <StaticImage 
                        src="../../images/foto-kowlier.jpg" 
                        alt="Tomato Festival" 
                        placeholder="blurred"
                        />    
          </Grid>
          <Grid item md={4} xs={12}>
            <Box className={classes.textBox}>
              <Typography className={classes.boxText} variant="body2">
                Deze eerste editie van TOMATO FESTIVAL zal doorgaan van 
                16 tot 18 augustus onder het regenproof dak van Bolwerk.
                Omwille van je-weet-wel is het in iets beperktere kring, 
                maar van enige muzikale beperking is géén sprake.
                met o.a. School is cool, ...
              </Typography>
            </Box>
          </Grid>
      </Grid>
      </Box>
    </Container>

)}

export default IndexPage
